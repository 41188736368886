@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media(max-width:1440px){
  .Home-Container{
    max-width: 1440px;
  }
}

@media(max-width:1024px){
  .Home-Container{
    max-width: 1024px;
  }
}

@media(max-width:768px){
  .Home-Container{
    max-width: 768px;
  }
}


@media(max-width:425px){
  .Home-Container{
    max-width: 425px;
  }
}
@media(max-width:390px){
  .Home-Container{
    max-width: 390px;
    height: 100%;
  }
}
@media(max-width:360px){
  .Home-Container{
    max-width: 360px;
    height: 100%;
  }
}

@media(max-width:375px){
  .Home-Container{
    max-width: 375px;
    height: 100%;
  }
}

@media(max-width:320px){
  .Home-Container{
    max-width: 320px;
    height: 100%;
  }
  .Footer-div{
    padding: 2vw;
  }
  .Community-div{
    padding-right:10px;
    padding-left: 7px;
  }
}